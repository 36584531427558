import React from "react";
import classes from "./Blog.module.css";
// ICONS
// import { Icon } from "@iconify/react";
// import newspaperIcon from "@iconify/icons-ion/newspaper";
// Primary Components
import BlogCard from "../../components/HomePage/BlogCard/BlogCard";
// pdfs
// https://firebasestorage.googleapis.com/v0/b/projet-homy.appspot.com/o/victoria-planche-1.pdf?alt=media&token=d0f5d376-706a-411b-908c-aa40cd577199
// import pdf from "../../assets/homePage/victoria-planche-1.pdf";

const Blog = (props) => {
  return (
    <div className={classes.Blog}>
      {/* <BlogCard
        title="Principe de la résidentialisation"
        description="Sécurisation et création de lieux de rencontre"
        date="Le 03 fevrier 2021"
        imgUrl="thumbnailpdf1.png"
        blogPostHandler={() =>
          window.open(
            `https://firebasestorage.googleapis.com/v0/b/projet-homy.appspot.com/o/victoria-planche-1.pdf?alt=media&token=d0f5d376-706a-411b-908c-aa40cd577199`,
            "_self"
          )
        }
      />
      <BlogCard
        title="Principe de la réhabilitation"
        description="Une rénovation complète qui tend vers plus de confort en milieu tropical."
        date="Le 03 fevrier 2021"
        imgUrl="thumbnailpdf2.png"
        blogPostHandler={() =>
          window.open(
            `https://firebasestorage.googleapis.com/v0/b/projet-homy.appspot.com/o/victoria-planche-2.pdf?alt=media&token=93ec3fb2-756e-4140-a1f7-709cae273372`,
            "_self"
          )
        }
      /> */}
      <BlogCard
        title="Principe de la résidentialisation"
        description="Sécurisation et création de lieux de rencontre"
        date="Le 03 fevrier 2021"
        imgUrl="thumbnailpdf1.png"
        blogPostHandler={() =>
          window.open(
            `${process.env.PUBLIC_URL}/victoria-planche-1.pdf`,
            "_self"
          )
        }
      />
      <BlogCard
        title="Principe de la réhabilitation"
        description="Une rénovation complète qui tend vers plus de confort en milieu tropical."
        date="Le 03 fevrier 2021"
        imgUrl="thumbnailpdf2.png"
        blogPostHandler={() =>
          window.open(
            `${process.env.PUBLIC_URL}/victoria-planche-2.pdf`,
            "_self"
          )
        }
      />
      <BlogCard
        blogPostHandler={props.blogPostHandler}
        title="L’association ADSL"
        description="L'association ADSL créée en décembre 2016 est installée dans la Ville de Saint-André. Nous ne nous imposons pas de limites géographiques..."
        date="Le 23 juin 2020"
        imgUrl="ADSL_00.png"
      />
    </div>
  );
};

export default Blog;
